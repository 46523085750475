import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Container, DropdownToggle, Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import { Link, withRouter } from 'react-router-dom';

import { Menu as MenuIcon } from "react-feather"

import { ThemeContext } from "../context/ThemeContext";
import JCLogoWhite from "components/Logo_White";
import JCLogo from "components/Logo";
import EmployersDropDown from "./EmployersDropDown";
import JobSeekerDropDown from "./JobSeekerDropDown";
import { requestDomain } from "../../homepage/services/SeekerServices";

const HomeHeader = ({ inverted, employersActive, jobSeekerActive, history, location}) => {
    const context = useContext(ThemeContext);
    const [showSignIn, setShowSignin] = useState(false);
    const [headerScrollClass, setHeaderScrollClass] = useState("");

    const handleWindowScroll = () => {
        if (window.scrollY) {
            if (window.scrollY > 130) {
                setHeaderScrollClass(" header_opaque header_scrolling ");
            } else {
                setHeaderScrollClass(" ");
            }
        }
    }

    const toggleSignIn = () => setShowSignin(!showSignIn);

    useEffect(() => {
        document.addEventListener("scroll", handleWindowScroll, false);
        return () => {
            document.removeEventListener("scroll", handleWindowScroll);
        }
    });

    return (
        <header className={"upward_header " + (context ? "header_" + context : "") + " " + headerScrollClass + (inverted ? " upward_header_inverted " : "")}>
            <nav className="navbar fixed-top">
                <Container>
                    <div className="clearfix" style={{ "width": "100%" }}>
                        <Row className="">
                            <Col xs="4" md="2" className="mr-auto">
                                <a className="navbar-brand" href="/">
                                    { (location.pathname !== "/" && location.pathname !== "/partners" && location.pathname !== "/mission") && <JCLogoWhite />}
                                    { (location.pathname === "/" || location.pathname === "/partners" || location.pathname === "/mission") && <JCLogo />}
                                </a>
                            </Col>
                            <Col xs="2" md="1" lg="4" xl="3" className="order-xs-4 order-md-12">
                                <div className="text-right d-lg-none">
                                    <Dropdown toggle={toggleSignIn} isOpen={showSignIn} className={"upward_header_dropdown " + (inverted ? " dropdown_inverted " : "")} >
                                        <DropdownToggle className="btn-block w-auto" color="dark" caret={false}>
                                            <MenuIcon size={24} />
                                            <DropdownMenu flip={false} right>
                                                <DropdownItem tag={Link} to="/" className="dropdown-head loginMargin"><span>Job seeker</span></DropdownItem>
                                                <DropdownItem tag="a" href={`${requestDomain}/results/?pageNum=1&reg=y`}>Create an account</DropdownItem>
                                                <DropdownItem tag="a" href={`${requestDomain}/results`} >Search jobs</DropdownItem>
                                                <DropdownItem tag={Link} to="/?signin=y" >Job seeker sign in</DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem tag={Link} to="/employers" className="dropdown-head loginMargin"><span>Employer</span></DropdownItem>
                                                <DropdownItem tag={Link} to="/employers?register=y" >Post a job</DropdownItem>
                                                <DropdownItem tag={Link} to="/employers?pricing=y" >Pricing</DropdownItem>
                                                <DropdownItem tag={Link} to="/enterprise" >Enterprise</DropdownItem>
                                                <DropdownItem tag={Link} to="/enterprise#serveIndustries" >By industry</DropdownItem>
                                                <DropdownItem tag={Link} to="/employers?signin=y" >Employer sign in</DropdownItem>

                                            </DropdownMenu>
                                        </DropdownToggle>
                                    </Dropdown>
                                </div>
                                <div className="text-right d-none d-lg-block">
                                    {
                                        (location.pathname === "/partners") ?
                                            (<a href="/t/auth/login/jobcase?utm_source=UpwardPartner" className={"btn btn-lg btn-rounded btn-outline-light mr-3"}>Sign in</a>)
                                            :
                                            (<Link to="?signin=y" className={"btn btn-lg btn-rounded btn-outline-light mr-3"}>Sign in</Link>)
                                    }
                                    <a href="http://jobcase.com/employer-onboarding?utm_source=Upward" className={"btn btn-lg btn-rounded btn-dark-" + context}><b>Post a Job</b></a>
                                </div>
                            </Col>
                            <Col xs="12" md="8" lg="6" xl={{ "offset": 1 }} className="mr-auto d-none d-md-block order-md-2">
                                <div className="d-flex justify-content-center">
                                    <div className="px-2">
                                        <JobSeekerDropDown active={!!jobSeekerActive}></JobSeekerDropDown>
                                    </div>
                                    <div className="px-2">
                                        <EmployersDropDown active={!!employersActive}></EmployersDropDown>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </nav>
        </header>
    )
}

export default withRouter(HomeHeader);
